.title {
    font-size: 6em;
    font-family: brume;
    color: #ffffff;
    text-align: left;
    letter-spacing: 3.6px;
    opacity: 1;
    margin: 0;
    line-height: 1.1;
}
.subtitle{
    font-family: raleway !important;
    color: #ffffff;
    text-align: left;
    margin: auto !important;
    font-size: 1.3em;
}
.subtitle-home{
    font-family: raleway !important;
    color: #ffffff;
    text-align: center;
    margin: auto !important;
}
.more-btn{
    margin: 24px 32px;
    border-radius: 50%;
    height: 52px;
    width: 52px;
    color: #D1D1D1;
    border: none;
    cursor: pointer;
    font-family: hagin-caps;
    position: relative;
    top: 0;
    background: linear-gradient(to bottom, rgba(229,105,241,1) 0%, rgba(81,205,213,1) 100%);

}
.more-btn a{
    color: white;
}
.button2{
    background: transparent linear-gradient(257deg, #A290F6 0%, #4259E9 100%) 0% 0% no-repeat padding-box;
    border-radius: 40px !important;
    opacity: 1;
    margin-top: 85px !important;
    width: 300px;
    height: 55px;
    font-family: brume;
    color:"white";
    display: inline-block;

}
.button3{
    background: transparent linear-gradient(257deg, #A290F6 0%, #4259E9 100%) 0% 0% no-repeat padding-box;
    border-radius: 40px !important;
    opacity: 1;
    margin-top: 85px !important;
    width: 300px;
    height: 55px;
    color:"white";
    display: inline-block;
    left: 470px!important;
}
.button1{
    border-radius: 40px !important;
    opacity: 1;
    width: 300px;
    height: 55px;
    font-size: 1em !important;
    font-family: brume !important;
    border-bottom-width: 200px;
    top: 35px;
    left: 200px;
    background-color: white  !important;
    text-transform:none !important;
}
.more-btn:hover{
    background: linear-gradient(to bottom, rgba(229,105,241,1) 0%, rgba(81,205,213,1) 100%);
    -webkit-box-shadow: 0px 0px 6px 24px rgba(32, 50, 94, 0.478);
    -moz-box-shadow: 0px 0px 6px 24px rgba(32, 50, 94, 0.687);
    box-shadow: 0px 0px 6px 24px rgba(32, 50, 94, 0.745);
    color: white;
}

.more-btn:hover .playIcon{
    transform: scale(1.6);
}
.bord{
    border-style: solid;
    border-width: 1px;
}
.PaddingContact{
    overflow:unset!important;
    padding-top: 40px;
    padding-right: 40px;
    padding-left: 40px;
    padding-bottom: 80px;
    position: sticky;
    z-index: 2;
}
.TextHello{
    font-size: 7em !important;
    right: 167px !important;
}
